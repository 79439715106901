import React from 'react';
import './Header.css';
import Modal from '../Modal/Modal';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logout from './logoutbtn';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import Logo from '../../assets/yardcanlogo.png';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { logout } from '../../redux/slices/user';
import { addStripe } from "../../redux/slices/payment";
import { Hidden } from '@mui/material';
import { HashLink as Linki } from 'react-router-hash-link';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Header = (props) => {
  const data = useSelector((state) => state.user);
  const state = useSelector((state) => state.user);
  const stripeState = useSelector((state) => state.payment);
  const [expanded, setexpaned] = React.useState(false);
  const dispatch = useDispatch();

   //countries supported on Stripe Payment
   const countries = [{name: 'United States', id: 'US', flag: '/img/country flags/united-states.svg'}, 
   {name: 'Italy', id: 'IT', flag: '/img/country flags/italy.svg'}, 
   {name: 'Canada', id: 'CA', flag: '/img/country flags/canada.svg'}]


  React.useEffect(() => {
    if(stripeState.isStripeAcCreated){
      if(stripeState.stripeLink !== '')
      window.location.replace(stripeState.stripeLink)
    }
  }, [stripeState])

  const createStripeHandler = (id) => {
    dispatch(addStripe(id))
  }

  const CountrySelector = () => {
    return (
          <OverlayTrigger
            trigger="click"
            key='popover'
            placement='top'
            overlay={
              <Popover id={`popover-positioned-top`}>
                <Popover.Header as="h3">Select Country</Popover.Header>
                <Popover.Body>
                  {console.log('Triggered.then why not ?')}
                  {countries.map(country => 
                  <div onClick={() => createStripeHandler(country.id)}
                  key={country.id} id={country.id} style={{marginTop:'1em', cursor:'pointer', display:'flex'}}> 
                  <img style={{height:20}} src={country.flag}/>
                  <option>{country.name}</option></div>
                 )}
                </Popover.Body>
              </Popover>
            }
          >
            <Button>Connect to Stripe</Button>
          </OverlayTrigger>
    );
  }

  return (
    <>
      <Navbar
        bg="white"
        fixed="top"
        expand="lg"
        expanded={expanded}
        className="m-0 p-0"
        style={{
          boxShadow: '0 0 8px 0 rgb(0 0 0 / 12%)',
          padding: '0',
          height: '65px'
        }}
      >
        <div
          style={{
            margin: '0',

            marginLeft: '10px',
            marginTop: '2px'
          }}
        >
          <img src={Logo} alt={'logo'} style={{ maxHeight: '63px' }} />
        </div>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="m-0 p-0"
          style={{ boxShadow: 'none', border: 'none' }}
          onClick={() => setexpaned(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="m-0 p-0 bg-white">
          <Nav
            className="justify-content-md-center"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap'
            }}
            onClick={() => {
              setexpaned(false);
            }}
          >
            <Link to="/" className="link">
              <Nav.Link
                className=" link"
                href="/listing"
                style={{
                  color: '#798791',
                  fontSize: '14px',
                  marginLeft: '2rem',
                  paddingLeft: '2rem'
                }}
              >
                Home
              </Nav.Link>
            </Link>
            <Link to="/listing" className="link">
              <Nav.Link
                className=" link"
                href="/listing"
                style={{
                  color: '#798791',
                  fontSize: '14px',
                  marginLeft: '2rem',
                  paddingLeft: '2rem'
                }}
              >
                Listing
              </Nav.Link>
            </Link>
            <Linki to="/#HowWeWork" className="link">
              <Nav.Link
                className=" link"
                href="#HowWeWork"
                style={{
                  color: '#798791',
                  fontSize: '14px',
                  marginLeft: '2rem',
                  paddingLeft: '2rem'
                }}
              >
                How We Work?
              </Nav.Link>
            </Linki>
            <Linki to="/becomeahost" className="link">
              <Nav.Link
                className=" link"
                href="/#becomeahost"
                style={{
                  color: '#798791',
                  fontSize: '14px',
                  marginLeft: '2rem',
                  paddingLeft: '2rem'
                }}
              >
                Become A Host
              </Nav.Link>
            </Linki>
          </Nav>
          <Hidden mdUp>
            <div style={{ padding: '10px' }}>
              {state?.user?.user?.host && (
                <Accordion style={{ width: '100%' }}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Seller Tools</Accordion.Header>
                    <Accordion.Body
                      style={{ display: 'flex', flexDirection: 'column' }}
                      onClick={() => {
                        setexpaned(false);
                      }}
                    >
                      <Link
                        style={{
                          padding: '10px',
                          width: '100%',
                          borderBottom: '1px solid #eeeeee',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left'
                        }}
                        to="/addlisting"
                      >
                        Add Listing
                      </Link>
                      <Link
                        className="link"
                        style={{
                          padding: '10px',
                          width: '100%',
                          borderBottom: '1px solid #eeeeee',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left'
                        }}
                        to="/managelistings"
                      >
                        Manage Listing
                      </Link>
                      <Link
                        className="link"
                        style={{
                          padding: '10px',
                          width: '100%',
                          borderBottom: '1px solid #eeeeee',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left'
                        }}
                        to="/mytransactions/seller"
                      >
                        Transactions
                      </Link>
                      <CountrySelector/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>
          </Hidden>
          <Hidden mdUp>
            <div style={{ padding: '10px' }}>
              {
                <Accordion style={{ width: '100%' }}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Customer Tools</Accordion.Header>
                    <Accordion.Body
                      style={{ display: 'flex', flexDirection: 'column' }}
                      onClick={() => {
                        setexpaned(false);
                      }}
                    >
                      <Link
                        className="link"
                        style={{
                          padding: '10px',
                          width: '100%',
                          borderBottom: '1px solid #eeeeee',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left'
                        }}
                        to="/mybookings"
                      >
                        My Bookings
                      </Link>
                      <Link
                        className="link"
                        style={{
                          padding: '10px',
                          width: '100%',
                          borderBottom: '1px solid #eeeeee',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left'
                        }}
                        to="/mytransactions/customer"
                      >
                        My Transactions
                      </Link>
                      <Link
                        className="link"
                        style={{
                          padding: '10px',
                          width: '100%',
                          borderBottom: '1px solid #eeeeee',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left'
                        }}
                        to="/myfavourite"
                      >
                        My Favourites
                      </Link>
                      <Link
                        className="link"
                        style={{
                          padding: '10px',
                          width: '100%',
                          fontWeight: 'bold',
                          color: 'gray',
                          textAlign: 'left',
                          borderBottom: '1px solid #eeeeee'
                        }}
                        to="/editprofile"
                      >
                        Profile Edit
                      </Link>

                      <div
                        className="link"
                        onClick={() => {
                          dispatch(logout(state.user.tokens.access));
                        }}
                        style={{
                          padding: '10px',
                          width: '100%',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          color: 'gray',
                          cursor: 'pointer'
                        }}
                        to="/addlisting"
                      >
                        Logout
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
            </div>
          </Hidden>
          <div
            onClick={() => !data?.user && setexpaned(false)}
            style={{ padding: '0px', margin: 0, height: 'inherit' }}
          >
            {data?.user ? <Logout setexpand={setexpaned} /> : <Modal />}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
