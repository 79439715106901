import axios from "../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { opensnackbar } from "./user";

const initialState = {
  isStripeAcCreated: false,
  stripeLink : ''
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    // GET PRODUCTS
    createAccount(state, action) {
      state.isStripeAcCreated = true;
      state.stripeLink = action.payload
    }}
  })



// Reducer
export default slice.reducer;

export const addStripe = (countryId) => {
  return async (dispatch) => {
    return await axios({
      method: "post",
      url: "/api/v1/payment/stripe/onboarding/initiate",
      data : {"country": `${countryId}`}
    }).then(async (res) => {
        dispatch(opensnackbar("success", "Stripe Account Added"));
        dispatch(slice.actions.createAccount(res.data.data.url));
    });
  };
};
