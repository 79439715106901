import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Home,
  Listing,
  ViewDetail,
  BookNow,
  ThankYou,
  EditProfile,
  Addlisting,
  Managelisting,
  Editlisting,
  Signup,
  Mybookings,
  Favourites,
  Reservations,
  Blockdates,
  Mytransactions,
  Becomeahost,
  StripeAddedPage
} from './Pages';
import Policy from './Pages/policy';
import PrivateRoute from './privateroute';

const Routes = () => {

  const { user } = useSelector(state => state.user)

  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/listing" component={Listing} />
      <Route exact path="/Signup" component={Signup} />
      <Route exact path="/becomeahost" component={Becomeahost} />
      <Route exact path="/view-detail/:id" component={ViewDetail} />
      { user && <Route exact path="/payment/stripe/onboarding/complete" component={StripeAddedPage} />}
      <Route exact path="/policy/:id" component={Policy} />
      <Route
        exact
        path="/book-now/:id"
        render={() => (
          <PrivateRoute>
            <BookNow />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/editprofile"
        render={() => (
          <PrivateRoute type="exclude">
            <EditProfile />
          </PrivateRoute>
        )}
      />

      <Route
        render={() => (
          <PrivateRoute>
            <ThankYou />
          </PrivateRoute>
        )}
        exact
        path="/thankyou"
      />

      <Route
        exact
        path="/addlisting"
        render={() => (
          <PrivateRoute>
            <Addlisting />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/editlisting/:id"
        render={() => (
          <PrivateRoute>
            <Editlisting />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/managelistings"
        render={() => (
          <PrivateRoute>
            <Managelisting />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/mybookings"
        render={() => (
          <PrivateRoute>
            <Mybookings />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/myfavourite"
        render={() => (
          <PrivateRoute>
            <Favourites />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/view-reservations/:id"
        render={() => (
          <PrivateRoute>
            <Reservations />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/blockedDates/:id"
        render={() => (
          <PrivateRoute>
            <Blockdates />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/mytransactions/:type"
        render={() => (
          <PrivateRoute>
            <Mytransactions />
          </PrivateRoute>
        )}
      />
    </>
  );
};

export default Routes;
