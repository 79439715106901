import { CallMadeOutlined } from '@mui/icons-material';
import { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/colors/green.css';
import './style.css'

export default function Multidatepicker({
  element,
  style,
  className,
  value,
  onChange,
  scheduleDateStrings
}) {
  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const [values, setValues] = useState([]);

  function isSlotsEmpty(mapDate) {
    if (scheduleDateStrings) {
      const data = scheduleDateStrings.includes(mapDate)
      return data;
    }
  }

  return (
    <DatePicker
      multiple
      // range
      format='YYYY-MM-DD'
      className={'green'}
      containerClassName={'w-100'}
      value={value}
      onChange={onChange}
      render={(_, openCalendar) => {
        return (
          <div
            onClick={openCalendar}
            className="d-flex justify-content-between align-items-center w-100"
          >
            <label style={{ cursor: 'pointer' }}>Please Select Date :-</label>
            <Icon />
          </div>
        );
      }}
      mapDays={({ date }) => {
        let className;
        const mapDate = date.format('YYYY-MM-DD');

        if (!isSlotsEmpty(mapDate)) {
          className = "noslots";
        }
        // if (isInService(mapDate)) className = "in-service";
        if (className) {
          return { className };
        }
      }}
      plugins={[<DatePanel />]}
      minDate={new Date()}
    />
  );
}
