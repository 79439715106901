import { createSlice } from '@reduxjs/toolkit'
import axios from '../../axios'
const initialState = {
  policies: [],
  policy: {},
}

const slice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    // START LOADING

    // GET Policies
    policies(state, action) {
      state.policies = action.payload
    },
    currentSelectedPolicy(state, action) {
      state.policy = action.payload
    },
  },
})

// Reducer
export default slice.reducer

export const getPolicies = () => {
  return async (dispatch) => {
    return await axios({
      method: 'get',
      url: '/api/v1/policy/viewall/public',
    }).then(async (res) => {
      dispatch(slice.actions.policies(res.data.data))
    })
  }
}

export const getPolicy = (id) => {
  return async (dispatch) => {
    return await axios({
      method: 'get',
      url: `/api/v1/policy/view/${id}/public`,
    }).then(async (res) => {
      dispatch(slice.actions.currentSelectedPolicy(res.data.data))
    })
  }
}
