import { ArrowRight } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom'
import Bannerbg from '../../assets/title-bg.jpg'
import hostbg from '../../assets/host.jpg'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPolicy } from '../../redux/slices/policy'

const Policy = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { policy } = useSelector((state) => state.policy)
  useEffect(() => {
    dispatch(getPolicy(id))
  }, [id])

  console.log('jk,dbjklsf', policy)

  return (
    <div className="d-flex justify-content-center flex-column">
      <section
        className="title-transparent page-title"
        style={{ backgroundImage: `url(${Bannerbg})` }}
      >
        <div className="container">
          <div className="title-content">
            <h1>{policy?.title}</h1>
            <div className="breadcrumbs ">
              <Link to="/">Home</Link>
              <ArrowRight />
              <span className="current">{policy?.title}</span>
            </div>
          </div>
        </div>
      </section>
      <div className="clearfix" />
      <section style={{ minHeight: 300 }}>
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: policy?.content }}></div>
        </div>
      </section>
    </div>
  )
}
export default Policy
